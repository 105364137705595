<template>
    <v-dialog v-model="isOpen" max-width="500px">
        <v-card>
            <v-card-title class="delete__title">{{ $t('are_you_sure_to_execute_this_command') }}</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="onClose">{{ $t('cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="onConfirm">{{ $t('ok') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isOpen: { type: Boolean },
        onClose: { type: Function },
        onConfirm: { type: Function },
    },
};
</script>

<style lang="scss">
.delete__title {
    font-size: 20px !important;
    justify-content: center;
}
</style>
