<template lang="">
    <div class="exportExel">
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full px-20 pb-20" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.Batching_Process') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <a-collapse v-model="activeKey">
                            <a-collapse-panel key="1" :header="$t('cms.step_1_download_file')">
                                <div class="content-container">
                                    <p class="content" @click="onClickDownload('USER')">
                                        <span class="mdi mdi-file-document"></span>{{ $t('cms.download_all') }}
                                    </p>
                                    <p class="content" @click="onClickDownload('TEMPLATE')">
                                        <span class="mdi mdi-file-document"></span>{{ $t('cms.download_template') }}
                                    </p>
                                </div>
                            </a-collapse-panel>
                            <a-collapse-panel key="2" :header="$t('cms.step_2_edit_content')">
                                <p class="content-text" v-if="currentLanguage === 'en'">
                                    You can update or create a batch of new users in one file<br />
                                    - In case of bulk create: You are need to add password for the user in column [G] of
                                    excel file<br />
                                    - In case of bulk update: Password is not required, email update is not allowed<br /><br />

                                    Note Excel File:<br />
                                    - Column A: email (Required)<br />
                                    - Column B: full name (Required)<br />
                                    - Column C: avatar (Optional) [Only accept link]<br />
                                    - Column D: gender (Optional) [MALE, FEMALE, OTHER] OTHER is default<br />
                                    - Column E: role (Optional) [USER or MANAGER], USER is default<br />
                                    - Column F: password (If create is Required, If update is Optional)<br />
                                </p>
                                <p class="content-text" v-else>
                                    「更新」と「新規作成 」を1つのファイルで一括で行うことはできます。<br />
                                    - 一括作成する場合：
                                    エクセルファイルの[G]列に、ユーザーのパスワードを追加する必要があります。<br />
                                    - 一括更新の場合：パスワードは不要、メールでの更新は不可<br />
                                    <br />
                                    ノートエクセルファイル：<br />
                                    - A列：電子メール（必須）<br />
                                    - B欄：フルネーム（必須）<br />
                                    - C列：アバター（オプション）[リンクのみ受け付けます。］<br />
                                    - D列：性別（オプション） [MALE, FEMALE, OTHER] OTHERは既定です。<br />
                                    - E列：役割（オプション）[USERまたはMANAGER]、USERは既定です。<br />
                                    - F列：パスワード（作成の場合が必須、更新の場合がオプション）<br />
                                </p>
                            </a-collapse-panel>
                            <a-collapse-panel key="3" :header="$t('cms.step_3_upload_file')">
                                <p>{{ $t('cms.only_accept_file') }}</p>
                                <input ref="fileRef" type="file" accept=".xlsx, .xls" @change="onChangeFile" />
                            </a-collapse-panel>
                            <Confirm :isOpen="isOpenModel" :onClose="onCloseConfirm" :onConfirm="onAfterConfirm" />
                        </a-collapse>
                    </template>
                    <div class="userUpload__box">
                        <div class="userUpload__list" v-if="arrayEmailCreate.length > 0">
                            <h6>{{ $t('cms.users_created') }}</h6>
                            <a-list bordered :data-source="arrayEmailCreate">
                                <a-list-item slot="renderItem" slot-scope="item, index">
                                    {{ `${index + 1}. ${item}` }}
                                </a-list-item>
                            </a-list>
                        </div>
                        <div class="userUpload__list" v-if="arrayEmailUpdate.length > 0">
                            <h6>{{ $t('cms.users_updated') }}</h6>
                            <a-list bordered :data-source="arrayEmailUpdate">
                                <a-list-item slot="renderItem" slot-scope="item, index">
                                    {{ `${index + 1}. ${item}` }}
                                </a-list-item>
                            </a-list>
                        </div>
                    </div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { cmsUploadExcelApi } from '../../api';
import Confirm from '../../components/CMS/Modal/Confirm.vue';
import { mixinLoadings, mixinToasts } from '../../mixins';

export default {
    components: { Confirm },
    mixins: [mixinToasts, mixinLoadings],

    data() {
        return {
            activeKey: ['1', '2', '3'],
            isOpenModel: false,
            filePath: '',
            arrayEmailCreate: [],
            arrayEmailUpdate: [],
            currentLanguage: this.$store.getters.getLang,
        };
    },

    computed: {
        ...mapState('excel', ['isLoading']),
    },
    methods: {
        ...mapActions('excel', ['getDownUser', 'getDownUserSample']),

        async onChangeFile(e) {
            const file = e.target.files[0];
            this.filePath = file;
            if (file) {
                this.isOpenModel = true;
            }
        },

        async onCloseConfirm() {
            this.isOpenModel = false;
            this.$refs.fileRef.value = '';
        },

        async onAfterConfirm() {
            this.onCloseConfirm();
            this.showLoading();

            try {
                const body = new FormData();
                body.append('file', this.filePath);
                const res = await cmsUploadExcelApi.uploadExcel(body);
                const { arrayEmailCreate, arrayEmailUpdate } = res?.results?.object;
                this.arrayEmailCreate = arrayEmailCreate;
                this.arrayEmailUpdate = arrayEmailUpdate;
            } catch (error) {
                this.showError(error);
            } finally {
                this.hideLoading();
            }
        },

        async onClickDownload(type) {
            this.showLoading();
            if (type === 'TEMPLATE') {
                await this.getDownUserSample();
            } else {
                await this.getDownUser();
            }
            this.hideLoading();
        },
    },
};
</script>

<style lang="scss">
.exportExel {
    & .ant-card-head {
        padding: 0;
    }
}

.content-container {
    display: flex;
}

.content {
    width: 50%;
    margin-bottom: 0 !important;
    color: cornflowerblue;
    cursor: pointer;
}

.content-text {
    margin-bottom: 0 !important;
    font-size: 14px;
}

.userUpload__box {
    margin-top: 30px;
    & .userUpload__list + .userUpload__list {
        margin-top: 20px;
    }
    & h6 {
        font-size: 18px;
    }
}

.userUpload__btn {
    margin-top: 20px;
    text-align: center;
}
</style>
